import i18n from "@/i18n";

export default [
  {
    path: "/lms-theme-loop",
    name: "ThemeLoop",
    meta: {
      title: "Theme Loop",
      trans: "Themenlooper",
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/LMSExamQuestions/ThemeLoop/ThemeLoop.vue"),
  },
];
