import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

export function SET_CATEGORY_LIST_IS_LOADING(state: State, isLoading: any) {
  state.categoryListIsLoading = isLoading;
}

export function SET_CATEGORY_LIST_ERROR(state: State, error: string) {
  state.categoryListError = error;
}

export function SET_CATEGORY_LIST(state: State, categoryList: any) {
  state.categoryList = categoryList;
}

export function SET_STUDENT_CATEGORIES_SUBCATEGORIES(state: State, data: any) {
  state.studentCategoriesSubcategories = data;
}

export function SET_STUDENT_SUBCATEGORY_DETAILED(state: State, data: any) {
  state.studentSubcategoryDetailed = data;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_CATEGORY_LIST_IS_LOADING,
  SET_CATEGORY_LIST_ERROR,
  SET_CATEGORY_LIST,
  SET_STUDENT_CATEGORIES_SUBCATEGORIES,
  SET_STUDENT_SUBCATEGORY_DETAILED,
};
