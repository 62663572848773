export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SET_CATEGORY_LIST_IS_LOADING = "SET_CATEGORY_LIST_IS_LOADING";
export const SET_CATEGORY_LIST_ERROR = "SET_CATEGORY_LIST_ERROR";
export const SET_STUDENT_CATEGORIES_SUBCATEGORIES = "SET_STUDENT_CATEGORIES_SUBCATEGORIES";
export const SET_STUDENT_SUBCATEGORY_DETAILED = "SET_STUDENT_SUBCATEGORY_DETAILED";
