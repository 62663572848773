import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import {
  SET_CATEGORY_LIST,
  SET_CATEGORY_LIST_IS_LOADING,
  SET_CATEGORY_LIST_ERROR,
  SET_STUDENT_CATEGORIES_SUBCATEGORIES,
  SET_STUDENT_SUBCATEGORY_DETAILED,
  IS_LOADING,
  SET_ERROR,
} from "@/store/LMSThemeLoop/types";
import i18n from "@/i18n";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
const messageDispatcher = new MessageDispatcher();
const URL = "/theme-loop";

export const findCategoriesSubcategories = async (store: any): Promise<void> => {
  store.commit(SET_CATEGORY_LIST_IS_LOADING, true);
  return await axios
    .get(`/theme-loop/category-subcategory`)
    .then(({ data }) => {
      store.commit(SET_CATEGORY_LIST_IS_LOADING, false);
      store.commit(SET_CATEGORY_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(SET_CATEGORY_LIST_IS_LOADING, false);
      store.commit(SET_CATEGORY_LIST_ERROR, response.data);
    });
};

/*
 * Currently we update only url but call it details so easier to understand and update
 * */

export const subcategoryDetailsUpsert = async (store: any, data: any): Promise<void> => {
  return await axios
    .put(`/theme-loop/${data.subCategoryNumber}/details`, data.subCategoryUrl, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch(({ response }) => {
      console.log("Exception...", response);
    });
};

export const findStudentCategoriesSubcategories = async (store: any, studentId: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${studentId}/student-category-subcategory`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_STUDENT_CATEGORIES_SUBCATEGORIES, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findStudentSubcategoryDetailed = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${options?.studentId}/${options?.subCategoryOfficialNumber}/${options?.licenceClass}/detailed`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_STUDENT_SUBCATEGORY_DETAILED, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  findCategoriesSubcategories,
  subcategoryDetailsUpsert,
  findStudentCategoriesSubcategories,
  findStudentSubcategoryDetailed,
};
