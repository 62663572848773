import Vue from "vue";
import Vuex from "vuex";
import Auth from "./Auth";
import Todo from "./Todo";
import Instructor from "./Instructor";
import Student from "./Student";
import Vehicle from "./Vehicle";
import DrivingLesson from "./DrivingLesson";
import Calendar from "./Calendar";
import Role from "./Role";
import TheoryLesson from "./TheoryLesson";
import Branch from "./Branch";
import PriceList from "./PriceList";
import RoleType from "./RoleType";
import Permission from "./Permission";
import KnowledgeArea from "./КnowledgeАrea";
import TheoryClass from "./TheoryClass";
import LicenseClass from "./LicenseClass";
import Country from "./Country";
import Language from "./Language";
import Tuv from "./Tuv";
import DrivingSchool from "./DrivingSchool";
import CostBearer from "./CostBearer";
import EmailConfig from "@/store/EmailConfig";
import AccountsConfig from "@/store/AccountsConfig";
import TextsConfig from "@/store/TextConfig";
import TheoryCourse from "./TheoryCourse";
import Snackbar from "@/store/Snackbar";
import StudentEducation from "./StudentEducation";
import BillingAddress from "./BillingAddress";
import Product from "./Product";
import Vat from "@/store/Vat";
import FinanceConfig from "@/store/FinanceConfig";
import StandardProduct from "@/store/StandardProduct";
import OtherAppointment from "@/store/OtherAppointment";
import Appointments from "./Appointments";
import MainTabs from "@/store/MainTabs";
import PracticalExam from "@/store/Exam/PracticalExam";
import Price from "./Price";
import TheoryExam from "./Exam/TheoryExam";
import ExamStatus from "@/store/ExamStatus";
import Saldo from "./Saldo";
import Payments from "@/store/Payments";
import EducationEvent from "./EducationEvent";
import StudentBookedProduct from "@/store/StudentBookedProduct";
import ExamSlots from "@/store/ExamSlots";
import StudentNote from "@/store/StudentNote";
import StudentDocument from "@/store/StudentDocument";
import ChecklistTemplate from "@/store/ChecklistTemplate";
import Modal from "@/store/Modal";
import CheckList from "@/store/CheckList";
import TheoryDocuments from "@/store/TheoryDocuments";
import PaymentRequest from "./PaymentRequest";
import StudentsFilter from "@/store/StudentsFilter";
import App from "./App";
import AutomaticPaymentTrigger from "./AutomaticPaymentTrigger";
import AutomaticPaymentEvent from "./AutomaticPaymentEvent";
import ClassicPay from "@/store/PaymentWorkflow/ClassicPay";
import LivePay from "@/store/PaymentWorkflow/LivePay";
import FlashPay from "@/store/PaymentWorkflow/FlashPay";
import PaymentWorkflow from "@/store/PaymentWorkflow";
import GrantingType from "./GrantingType";
import PaymentDocument from "./PaymentWorkflow/PaymentDocument";
import PaymentDocumentHistory from "./PaymentWorkflow/PaymentDocumentHistory";
import PartialInvoice from "./PaymentWorkflow/PartialInvoice";
import TheoryQuestionsVersion from "@/store/TheoryQuestionsVersion";
import LearnStatus from "@/store/LearnStatus";
import TrainingContract from "./TrainingContract";
import CustomBookedProduct from "@/store/CustomBookedProduct";
import CostBearerCustomBooking from "@/store/CostBearerCustomBooking";
import CostBearerPaymentDocument from "@/store/PaymentWorkflow/CostBearerPaymentDocument";
import CostBearerPaymentRequest from "@/store/CostBearerPaymentRequest";
import RegistrationInfo from "@/store/RegistrationInfo";
import TestingOrganizations from "@/store/TestingOrganizations";
import Document from "./Document";
import LivePayMandate from "@/store/PaymentWorkflow/LivePayMandate";
import StudentQuestionAnswer from "@/store/StudentQuestionAnswer";
import TheoryQuestions from "@/store/TheoryQuestions";
import TheoryPreExamProgress from "@/store/TheoryPreExamProgress";
import Mailbox from "@/store/Mailbox";
import Communication from "@/store/Communication";
import TheoryPreExams from "@/store/TheoryPreExams";
import ProofOfTraining from "@/store/ProofOfTraining";
import User from "@/store/User";
import Registrations from "./Registrations";
import EmailTemplates from "@/store/EmailTemplates";
import Emails from "@/store/Emails";
import ExamDurations from "@/store/ExamDurations";
import CalendarTheoryLesson from "@/store/CalendarTheoryLesson";
import StudentPayment from "@/store/StudentPayment";
import ExternalServices from "@/store/ExternalServices";
import CostBearerDocument from "@/store/CostBearerDocument";
import Report from "@/store/Report";
import Password from "@/store/Password";
import Finance from "@/store/Finance";
import Learn from "@/store/Learn";
import CalendarPracticalExam from "@/store/CalendarPracticalExam";
import Broadcast from "@/store/Broadcast";
import CostBearerNote from "@/store/CostBearerNote";
import RegionSpecificDocuments from "@/store/RegionSpecificDocuments";
import Messaging from "@/store/Messaging";
import Mandate from "@/store/Mandate";
import Gocardless from "@/store/Gocardless";
import DepositInvoice from "@/store/DepositInvoice";
import CommunicationTemplates from "@/store/CommunicationTemplates";
import Abibaro from "@/store/Abibaro";
import Filtering from "@/store/Filtering";
import Warnings from "@/store/Warnings";
import AutomaticCommunication from "@/store/AutomaticCommunication";
import Pulse from "@/store/Pulse";
import BKFConfig from "@/store/BKFConfig";
import DriveBuzzConfig from "@/store/DriveBuzzConfig";
import LMSExamQuestions from "@/store/LMSExamQuestions";
import LMSThemeLoop from "@/store/LMSThemeLoop";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: new Auth(),
    todo: new Todo(),
    instructor: new Instructor(),
    student: new Student(),
    vehicle: new Vehicle(),
    "driving-lesson": new DrivingLesson(),
    calendar: new Calendar(),
    role: new Role(),
    "theory-lesson": new TheoryLesson(),
    "theory-class": new TheoryClass(),
    branch: new Branch(),
    "knowledge-area": new KnowledgeArea(),
    "price-list": new PriceList(),
    "role-type": new RoleType(),
    permission: new Permission(),
    "license-class": new LicenseClass(),
    country: new Country(),
    language: new Language(),
    tuv: new Tuv(),
    "cost-bearer": new CostBearer(),
    "driving-school": new DrivingSchool(),
    "email-config": new EmailConfig(),
    "accounts-config": new AccountsConfig(),
    "theory-course": new TheoryCourse(),
    "text-config": new TextsConfig(),
    "bkf-config": new BKFConfig(),
    "drive-buzz-config": new DriveBuzzConfig(),
    "student-education": new StudentEducation(),
    "billing-address": new BillingAddress(),
    product: new Product(),
    snackbar: new Snackbar(),
    vat: new Vat(),
    "finance-config": new FinanceConfig(),
    "standard-product": new StandardProduct(),
    "other-appointment": new OtherAppointment(),
    appointments: new Appointments(),
    "main-tabs": new MainTabs(),
    price: new Price(),
    "practical-exam": new PracticalExam(),
    "theory-exam": new TheoryExam(),
    "exam-status": new ExamStatus(),
    saldo: new Saldo(),
    payments: new Payments(),
    "education-event": new EducationEvent(),
    "student-booked-product": new StudentBookedProduct(),
    "exam-slots": new ExamSlots(),
    "student-note": new StudentNote(),
    "student-document": new StudentDocument(),
    "cost-bearer-document": new CostBearerDocument(),
    "checklist-template": new ChecklistTemplate(),
    modal: new Modal(),
    "check-list": new CheckList(),
    "theory-documents": new TheoryDocuments(),
    "payment-request": new PaymentRequest(),
    "students-filter": new StudentsFilter(),
    app: new App(),
    "automatic-payment-trigger": new AutomaticPaymentTrigger(),
    "automatic-payment-event": new AutomaticPaymentEvent(),
    "classic-pay": new ClassicPay(),
    "live-pay": new LivePay(),
    "flash-pay": new FlashPay(),
    "payment-workflow": new PaymentWorkflow(),
    "granting-type": new GrantingType(),
    "payment-document": new PaymentDocument(),
    "payment-document-history": new PaymentDocumentHistory(),
    "partial-invoice": new PartialInvoice(),
    "theory-questions-version": new TheoryQuestionsVersion(),
    "learn-status": new LearnStatus(),
    "training-contract": new TrainingContract(),
    "custom-booked-product": new CustomBookedProduct(),
    "cost-bearer-custom-booking": new CostBearerCustomBooking(),
    "cost-bearer-payment-document": new CostBearerPaymentDocument(),
    "cost-bearer-payment-request": new CostBearerPaymentRequest(),
    "registration-info": new RegistrationInfo(),
    "testing-organizations": new TestingOrganizations(),
    document: new Document(),
    "live-pay-mandate": new LivePayMandate(),
    "student-question-answers": new StudentQuestionAnswer(),
    "theory-questions": new TheoryQuestions(),
    "theory-pre-exam": new TheoryPreExamProgress(),
    mailbox: new Mailbox(),
    communication: new Communication(),
    "broadcast-templates": new CommunicationTemplates("broadcast-templates"),
    "message-templates": new CommunicationTemplates("message-templates"),
    "theory-pre-exams": new TheoryPreExams(),
    "proof-of-training": new ProofOfTraining(),
    user: new User(),
    registrations: new Registrations(),
    "email-templates": new CommunicationTemplates("email-templates"),
    // "email-templates": new EmailTemplates(),
    emails: new Emails(),
    "exam-durations": new ExamDurations(),
    "calendar-theory-lesson": new CalendarTheoryLesson(),
    "student-payment": new StudentPayment(),
    "external-services": new ExternalServices(),
    report: new Report(),
    password: new Password(),
    finance: new Finance(),
    learn: new Learn(),
    "calendar-practical-exam": new CalendarPracticalExam(),
    broadcast: new Broadcast(),
    "cost-bearer-note": new CostBearerNote(),
    "region-specific-documents": new RegionSpecificDocuments(),
    messaging: new Messaging(),
    mandate: new Mandate(),
    gocardless: new Gocardless(),
    "deposit-invoice": new DepositInvoice(),
    abibaro: new Abibaro(),
    filtering: new Filtering(),
    warnings: new Warnings(),
    "automatic-messages": new AutomaticCommunication(),
    pulse: new Pulse(),
    lms: new LMSExamQuestions(),
    "lms-theme-loop": new LMSThemeLoop(),
  },
});
