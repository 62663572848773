import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getCategoryList = (state: State) => state.categoryList;
export const getCategoryListIsLoading = (state: State) => state.categoryListIsLoading;

export const getStudentCategoriesSubcategories = (state: State, data: any) => state.studentCategoriesSubcategories;

export const getBaseCategory = (state: State) => {
  return state.studentCategoriesSubcategories?.find((item: any) => item.name === "basic") || null;
};

export const getEducationCategory = (state: State) => {
  return state.studentCategoriesSubcategories?.filter((item: any) => item.name !== "basic") || null;
};

export const getStudentSubcategoryDetails = (state: State) => {
  return state.studentSubcategoryDetailed.details;
};

export const getStudentSubcategoryQuestions = (state: State) => {
  return state.studentSubcategoryDetailed.questions;
};

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getCategoryList,
  getCategoryListIsLoading,
  getStudentCategoriesSubcategories,
  getBaseCategory,
  getEducationCategory,
  getStudentSubcategoryDetails,
  getStudentSubcategoryQuestions,
};
